import React from 'react'

import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from '../components/layout'
import {
  Container
} from '../styles/EinrichtungsplanungStyles';
import PrsnlBeratung from '../components/einrichtungsplanung/prsnlBeratung'
import HomeStaging from '../components/einrichtungsplanung/homeStaging'
import InteriorDesign from '../components/einrichtungsplanung/interiorDesign'
import Businessplanung from '../components/einrichtungsplanung/businessplanung'
// import Ablauf from '../components/einrichtungsplanung/ablauf'
import Hero from '../components/einrichtungsplanung/Hero';
import Beratungsarten from '../components/einrichtungsplanung/Beratungsarten/Beratungsarten';
import Ablauf from '../components/einrichtungsplanung/Ablauf/Ablauf';

const Einrichtungsplanung = ({ data }) => {
  const content = data.allWpPage.nodes[0]
  const hero = content.pageTypeEinrichtungsplanungFields.heroCopy
  const types = content.pageTypeEinrichtungsplanungFields.types
  const steps = content.pageTypeEinrichtungsplanungFields.steps

  return (
    <Layout>
      <SEO title="Einrichtungsplanung" />

      <Hero
        hero={hero}
      />

      <Beratungsarten
        types={types}
      />

      <Ablauf
        steps={steps}
      />

    </Layout>
  );
}


export default Einrichtungsplanung;


export const query = graphql`
query {
  allWpPage(filter: {title: {eq: "Einrichtungsplanung"}}) {
    nodes {
      pageTypeEinrichtungsplanungFields {
        heroCopy {
          headline
          bodycopy
          heroimage {
            localFile {
              childImageSharp {
                fluid (quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
          logoimg {
            localFile {
              childImageSharp {
                fluid (quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        types {
          type
          bodycopy
          img {
            localFile {
              childImageSharp {
                fluid (quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        steps {
          bulletpoint
          title
          bodycopy
        }
      }
    }
  }
}
`