import * as React from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import useIntersectionObserver from "../../../hooks/useIntersectionObserver"
import Card from "./Card"

const Section = styled(motion.div)`
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 1rem;
  @media (min-width: 1028px) {
    margin-top: 15rem;
  }
`

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
`

const FlexContainer2 = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6rem;
`

const FlexItem2 = styled.div`
  flex-basis: 410px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  @media(min-width: 1280px) {
    justify-content: center;
  }
  :hover {
    color: #B9789F;
  }
`

const HeadlineWrapper = styled.div`
  margin-bottom: 6rem;
  text-align: center;
`

const Headline = styled.h3`
  color: #161616;
  font-family: "Courier New", Courier, monospace;
  font-size: ${props => props.size};
  font-weight: 700;
`

const CustomA = styled.a`
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.25rem;
  letter-spacing: 1.25px;

`

const variantsLeft = {
  hidden: {
    opacity: 0,
    x: -100,
    transition: {
      duration: 0.75,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.75,
    },
  },
}

const variantsRight = {
  hidden: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 0.75,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.75,
    },
  },
}

const Ablauf = props => {
  const order = props.steps.map(o => o.bulletpoint)
  const title = props.steps.map(o => o.title)
  const text = props.steps.map(o => o.bodycopy)

  return (
    <Section>
      <HeadlineWrapper>
        <Headline size="2rem">Wie läuft eine Planung ab?</Headline>
      </HeadlineWrapper>

      <FlexContainer>
        <Card
          backgroundColor="#FFCE9D"
          order={order[0]}
          title={title[0]}
          text={text[0]}
          variants={variantsLeft}
          align="flex-start"
        />
        <Card
          backgroundColor="#98EDC6"
          order={order[1]}
          title={title[1]}
          text={text[1]}
          variants={variantsRight}
          align="flex-end"
        />
        <Card
          backgroundColor="#FFCE9D"
          order={order[2]}
          title={title[2]}
          text={text[2]}
          variants={variantsLeft}
          align="flex-start"
        />
        <Card
          backgroundColor="#98EDC6"
          order={order[3]}
          title={title[3]}
          text={text[3]}
          variants={variantsRight}
          align="flex-end"
        />
      </FlexContainer>

      <HeadlineWrapper>
        <Headline size="2rem">Jetzt Termin vereinbaren!</Headline>
      </HeadlineWrapper>

      <FlexContainer2>
        <FlexItem2>
          <CustomA href="tel:004921141651600"><strong>Telefon:</strong> 0211 41651600</CustomA>
        </FlexItem2>
        <FlexItem2>
          <CustomA href="mailto:info@diewohnplaner.de"><strong>E-Mail:</strong> info@diewohnplaner.de</CustomA>
        </FlexItem2>
        <FlexItem2>
          <CustomA href="https://api.whatsapp.com/send?phone=4915236611286" title="Senden Sie uns eine Nachricht über WhatsApp!" target="_blank" rel="noreferrer noopener"><strong>WhatsApp:</strong> +49 152 36611286 </CustomA>
        </FlexItem2>
      </FlexContainer2>

      <HeadlineWrapper>
        <Headline size="1.75rem">Wir freuen uns auf Sie!</Headline>
      </HeadlineWrapper>
    </Section>
  )
}

export default Ablauf
