import React from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import useIntersectionObserver from "../../../hooks/useIntersectionObserver"

// mach Card Component draus

export const Wrapper = styled(motion.div)`
  align-self: ${props => props.align};
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  border-radius: 5vw;
  padding: 1rem;
  margin-bottom: 2.5rem;
  @media (min-width: 1280px) {
    width: 50%;
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  @media (min-width: 1280px) {
    justify-content: flex-end;
    align-items: center;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 1280px) {
    position: relative;
  }

`

export const Stamp = styled.div`
  height: 60px;
  width: 60px;
  background-color: #fff;
  border-radius: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
  font-size: 2.5rem;
  font-weight: 700;
  @media (min-width: 1280px) {
    position: absolute;
    top: 24px;
    left: 24px;
    height: 90px;
  width: 90px;
  }
`

const MobileHeadlineWrapper = styled.div`
  display: block;
  margin-left: 1rem;
  @media (min-width: 1280px) {
    display: none;
  }
`

const HeadlineWrapper = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: block;
    margin-bottom: 1rem;
  }
`

const H4 = styled.h4`
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
  font-weight: 700;
  @media (min-width: 1280px) {
    font-size: 2rem;
  }
`

const InnerCardContainer = styled.div`
  
`

const Paragraph = styled.div`
  font-size: 0.8rem;
  line-height: 2rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  padding-right: 3rem;
  width: 40ch;
  @media (min-width: 1280px) {
    width: 60ch;
  }
`

const Card = ({ backgroundColor, order, title, text, variants, align }) => {

  const { ref, visible } = useIntersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  })
  const controls = useAnimation()
  React.useEffect(() => {
    visible ? controls.start("visible") : controls.start("hidden")
  }, [controls, visible])
  return (
    // add conditional variants
    <Wrapper
      backgroundColor={backgroundColor}
      variants={variants}
      animate={controls}
      ref={ref}
      align={align}
    >
      <Header>

        <Stamp>{order}</Stamp>

        <MobileHeadlineWrapper>
        <H4>{title}</H4>
        </MobileHeadlineWrapper>

        </Header>

      <Container>

      <InnerCardContainer>
        <HeadlineWrapper>
        <H4>{title}</H4>
        </HeadlineWrapper>
        <Paragraph>{text}</Paragraph>
        </InnerCardContainer>

        </Container>
    </Wrapper>
  )
}

export default Card
