import React from "react"
import styled from "styled-components"
import LogoSlider from "../../../assets/logo-slider.jpg"

const Wrapper = styled.div`
    margin-top: 3.5rem;
    width: 100%;
    overflow: hidden;
`

const Ticker = styled.div`
    display: flex;
    height: 80px;
    animation: ticker linear 10s;
    animation-iteration-count: infinite;
    @keyframes ticker {
        0% {transform: translate3d(-100%, 0, 0); } 
    }
    @media(min-width: 1280px) {
      @keyframes ticker {
        0% {transform: none}
      }
    }
`

const References = styled.span`
  margin-left: 1rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
`

const LogoTicker = () => {
  return (
    <Wrapper>
      <References>Referenzen</References>
      <Ticker>
        <img src={LogoSlider} />
      </Ticker>
    </Wrapper>
  )
}

export default LogoTicker
