import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import LogoTicker from "./Referenzen/LogoTicker"
import { NewCopy } from "../../styles/components/typography"

const Section = styled.section`
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-bottom: 3rem;
  @media (min-width: 1280px) {
    margin-top: 3rem;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  flex-wrap: wrap;
  align-items: center;
`

const FlexItem = styled.div`
  flex-grow: 1;
  flex-basis: ${props => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
  @media(min-width: 1280px) {
    margin-right: 2rem;
  }
`

const HeadlineCopy = styled.h1`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.55rem;
  font-weight: 700;
  padding: 1rem;
  color: #111111;
  width: 25ch;
  @media(min-width: 1028px) {
    font-size: 2rem;
  }
`

const Image = styled(Img)``

const Hero = props => {
  return (
    <Section>
      <FlexWrapper>
        <FlexItem
        flex={"400px"}
         >
          <Img
            style={{ width: "100%" }}
            fluid={props.hero.heroimage.localFile.childImageSharp.fluid}
          />
        </FlexItem>
        <FlexItem
         flex={"400px"}
        >
          <div>
            <HeadlineCopy>{props.hero.headline}</HeadlineCopy>
            <NewCopy>{props.hero.bodycopy}</NewCopy>
          </div>
        </FlexItem>
      </FlexWrapper>

      <LogoTicker />
    </Section>
  )
}

export default Hero
