import * as React from "react"

function ArrowDown(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" {...props}>
      <path
        d="M6.82 108.12l63.67-63.69a2.24 2.24 0 013 0l63.67 63.69"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
    </svg>
  )
}

export default ArrowDown