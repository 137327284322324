import * as React from "react"

function ArrowUp(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" {...props}>
      <path
        d="M137.18 43.84l-63.67 63.69a2.24 2.24 0 01-3 0L6.82 43.84"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
    </svg>
  )
}

export default ArrowUp
