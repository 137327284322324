import React from "react"
import { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import ArrowUp from "../../../assets/arrow-down"
import ArrowDown from "../../../assets/arrow-up"

const Container = styled.section`
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(min-width: 1280px) {
    height: 100%
  }
`

const AccordionContainer = styled(motion.div)``

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const HeaderIcon = styled.i`
  margin-right: 1rem;
`

const AccordionButton = styled.button`
  cursor: pointer;
  border: none;
  background: inherit;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.75rem;
  font-weight: 700;
  text-align: left;
`

const Card = styled.div`
  position: relative;
`

const CardFlex = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
`

const MobileImageWrapper = styled.div`
  @media(min-width: 1028px) {
    display: none;
  }
`



const ImageWrapper = styled.div`
  @media(min-width: 1028px) {
  position: absolute;
  top: ${props => props.top};
  right: 0;
  width: 45%;
  }
`

const Left = styled.div`
  flex: 1 1 250px;
`

const Right = styled.div`
  display: none;
  @media (min-width: 1028px) {
    flex: 1 1 250px;
    display: block;
  }
`

const Paragraph = styled.div`
color: black;
  font-size: .9rem;
  line-height: 2.5rem;
  letter-spacing: 1.25px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Item = styled.div`
  flex: 50%;
`

const Accordion = props => {
  
  const [open, setOpen] = useState(0)

  const toggle = index => {
    if (open === index) {
      return setOpen(null)
    } else {
      setOpen(index)
    }
  }

  return (
    <AccordionContainer>
      
      {props.types.map((ele, index) => (
        <Card>
          <CardFlex>
            <Left>
          <AccordionHeader>
            <div style={{width: "20px", height: "20px", marginRight: "1rem"}}>
            {open === index ? <HeaderIcon><ArrowUp /></HeaderIcon> : <HeaderIcon><ArrowDown /></HeaderIcon>}
            </div>
            <AccordionButton onClick={() => toggle(index)} key={index} open={open}>
              {ele.type}
            </AccordionButton>
          </AccordionHeader>
            {open === index ? (
              <>
                <MobileImageWrapper 
                
                >
                  <Img
                    style={{ width: "100%" }}
                    fluid={ele.img.localFile.childImageSharp.fluid}
                  />
                </MobileImageWrapper>
                <Paragraph>{ele.bodycopy}</Paragraph>
                </>
                ) : null}
                </Left> 

                <Right>
                {open === index ? (
   
              <ImageWrapper
              top={
                index === 0 ? "0" :
                index === 1 ? "-60px" :
                index === 2 ? "-45px" :
                "unset"
                }
              >
                  <Img
                    style={{ width: "100%" }}
                    fluid={ele.img.localFile.childImageSharp.fluid}
                  />
              </ImageWrapper>
                
         
                    ) : null}


                </Right>

          </CardFlex>
        </Card>
      ))}
    </AccordionContainer>
  )
}

const Beratungsarten = props => {
  return (
    <Container>
      <Item backgroundColor="white">
        <Accordion types={props.types} />
      </Item>
    </Container>
  )
}

export default Beratungsarten
